@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Bubble chat */
.speech-bubble {
	position: relative;
	/* background: #9993; */
	border-radius: .4em;
  width: 100% !important;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	width: 0;
	height: 0;
	border: 1.094em solid transparent;
	border-right-color: #9992;
	border-left: 0;
	border-top: 0;
	/* margin-top: -0.547em; */
	margin-left: -1.094em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
